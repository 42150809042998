import React, { useState } from "react";
import { Container, Table, Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./FlowChart.css";
import img1 from "./assets/01.-SEGMENTACIÓN.png";
import img2 from "./assets/02.- VERDAD DE CAMPO (1) .jpeg";
import img3 from "./assets/03.- VERDAD DE CAMPO (2).jpg";
import img4 from "./assets/04.- ANALITICA DE DATA.jpg";
import img5 from "./assets/05.- ACTUADOR.jpeg";

const alertsData = [
  {
    title: "Segmentación",
    alertText: "Sistema de evaluación de la variabilidad espacial y temporal de los predios",
    modalContent: " Utiliza tecnologías avanzadas para evaluar y entender la variabilidad espacial y temporal de los terrenos agrícolas, permitiendo una planificación y manejo precisos del cultivo.",
    img: img1
  },
  {
    title: "Verdad de campo - Continuo",
    alertText: "Sistema de monitoreo continuo de variables",
    modalContent: " Este sistema implica la recolección de datos en tiempo real, proporcionando un flujo constante de información sobre las variables del campo. Es ideal para el seguimiento preciso de condiciones que cambian continuamente, como la humedad del suelo y las condiciones climáticas, permitiendo intervenciones rápidas y basadas en datos actuales.",
    img: img2
  },
  {
    title: "Verdad de campo - Discontinuo",
    alertText: "Sistema de monitoreo discontinuo",
    modalContent: "A diferencia del continuo, este sistema recopila datos en intervalos definidos o bajo ciertas condiciones preestablecidas. Es útil para variables que no requieren monitoreo constante, permitiendo una gestión eficiente de los recursos de monitoreo sin comprometer la precisión de los datos.",
    img: img3
  },
  {
    title: "Analítica de Data",
    alertText: "Sistema de logística y control de la producción",
    modalContent: "Incorpora sistemas avanzados de análisis de datos que procesan la información recogida para optimizar la logística y el control de la producción agrícola, permitiendo decisiones basadas en evidencias concretas y análisis predictivo.",
    img: img4
  },
  {
    title: "Actuadores",
    alertText: "Mecanización inteligente y robótica",
    modalContent: "Adopta soluciones de mecanización inteligente y robótica que responden automáticamente a las necesidades detectadas en el campo, ejecutando acciones correctivas de manera precisa y eficiente para mejorar los rendimientos y la sostenibilidad de las prácticas agrícolas.",
    img: img5
  }
];

const FlowChart = () => {
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (content) => {
    setModalContent(content);
    setShow(true);
  };

  return (
    <Container id="Flowchart" className="information-capture-container">
      <h1 className="flowchart-title">Flujograma</h1>
      <Table bordered className="flowchart-table">
        <thead>
          <tr>
            <th colSpan="3" className="titlesFlowTable">Captura de información (Diagnostico y Monitoreo)</th>
            <th className="titlesFlowTable">Interpretación y Soporte</th>
            <th className="titlesFlowTable">Acciones Correctivas</th>
          </tr>
          <tr>
            <th className="subtitlesFlowTable">Segmentación</th>
            <th colSpan="2" className="subtitlesFlowTable">Verdad de campo</th>
            <th className="subtitlesFlowTable">Analítica de Data</th>
            <th className="subtitlesFlowTable">Actuadores</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td onClick={() => handleShow(alertsData[0].modalContent)}>
              {alertsData[0].alertText}
            </td>
            <td onClick={() => handleShow(alertsData[1].modalContent)}>
              {alertsData[1].alertText}
            </td>
            <td onClick={() => handleShow(alertsData[2].modalContent)}>
              {alertsData[2].alertText}
            </td>
            <td onClick={() => handleShow(alertsData[3].modalContent)}>
              {alertsData[3].alertText}
            </td>
            <td onClick={() => handleShow(alertsData[4].modalContent)}>
              {alertsData[4].alertText}
            </td>
          </tr>
          <tr>
            <td>
              <img src={alertsData[0].img} className="imgFlowchart" alt={alertsData[0].title}/>
            </td>
            <td>
              <img src={alertsData[1].img} className="imgFlowchart" alt={alertsData[1].title}/>
            </td>
            <td>
              <img src={alertsData[2].img} className="imgFlowchart" alt={alertsData[2].title}/>
            </td>
            <td>
              <img src={alertsData[3].img} className="imgFlowchart" alt={alertsData[3].title}/>
            </td>
            <td>
              <img src={alertsData[4].img} className="imgFlowchart" alt={alertsData[4].title}/>
            </td>
          </tr>
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FlowChart;
