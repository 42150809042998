import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Capabilities2.css';
import img1 from './assets/img1.png'
import img2 from './assets/img2.png'
import img3 from './assets/img3.jpg'

const Capabilities2 = () => {
    return (
        <Container className='Capabilities2component'>
            <h2 className="text-center Capabilities2-title">Generar Capacidades</h2>
            <Row>
                <Col md={4}>
                    <Card className="mb-4 Capabilities2-cardtheme">
                        <Card.Img variant="top" src={img1} className='Capabilities2-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities2-cardtitle'>Unidades demostrativas</Card.Title>
                            <Card.Text className='Capabilities2-cardtext'>
                            Las unidades demostrativas implementan y muestran tecnologías agrícolas en práctica, enseñando y demostrando su efectividad La colaboración entre INIA y CORFO para crear AgroConecta combina a productores, técnicos y estudiantes. Actúan como centros de aprendizaje y experimentación en condiciones reales, permitiendo la observación directa de los beneficios y funcionamiento de innovaciones, fomentando su adopción en el sector
                            </Card.Text>
                            <Button className='homeproducts-button' target="_blank" rel="noopener noreferrer" variant="success" href='https://agroconecta-inia.cl/Proyectos'>Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 Capabilities2-cardtheme">
                        <Card.Img variant="top" src={img3} className='Capabilities2-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities2-cardtitle'>Colaboración INIA-CORFO</Card.Title>
                            <Card.Text className='Capabilities2-cardtext'>
                            La colaboración entre INIA y CORFO para crear AgroConecta combina a productores, técnicos y estudiantes. Actúan como centros de aprendizaje y experimentación en condiciones reales, permitiendo la observación directa de los beneficios y funcionamiento de innovaciones, fomentando su adopción en el sector. experticia técnica y financiamiento para modernizar la agricultura chilena mediante tecnologías AgTech. Este esfuerzo conjunto busca fomentar la innovación, mejorar la productividad agrícola y apoyar la sustentabilidad, beneficiando directamente a los productores y al sector agrícola nacional.
                            </Card.Text>
                            <Button className='homeproducts-button' target="_blank" rel="noopener noreferrer" variant="success" href='https://agroconecta-inia.cl/'>Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 Capabilities2-cardtheme">
                        <Card.Img variant="top" src={img2} className='Capabilities2-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities2-cardtitle'>Capacitaciones virtuales</Card.Title>
                            <Card.Text className='Capabilities2-cardtext'>
                            Las capacitaciones virtuales prácticas basadas en las tecnologías visualizadas en unidades demostrativas ofrecen a productores y técnicos formación interactiva y a distancia. Estas sesiones facilitan el entendimiento y la aplicación de innovaciones agrícolas, permitiendo a los participantes explorar el funcionamiento y beneficios de las tecnologías sin necesidad de estar presentes físicamente.
                            </Card.Text>
                            <Button className='Capabilities2-button' variant="success" target="_blank" rel="noopener noreferrer" href='https://agroconecta-inia.cl/Campus'>Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Capabilities2; 