import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Products.css';

import campus from './assets/campus.png'
import vigilancia from './assets/vigilancia.png'
import smartfield from './assets/smartfield.png'
import biblioteca from './assets/bliblioteca.jpg'


const Products = () => {
    return (
        <Container className='homeproductscomponent'>
            <h2 className="text-center homeproducts-title">Productos</h2>
            <Row>
                <Col md={3}>
                    <Card className="mb-3 homeproducts-cardtheme">
                        <Card.Img variant="top" src={campus} className='homeproducts-cardimg' />
                        <Card.Body>
                            <Card.Title className='homeproducts-cardtitle'>Campus</Card.Title>
                            <Card.Text className='homeproducts-cardtext'>
                            Capacitaciones virtuales en tecnologías agrícolas para mejorar las prácticas y adopción tecnológica.
                            </Card.Text>
                            <Button className='homeproducts-button' variant="success" href='https://smartfieldinia.cl/campusinia/' >Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3 homeproducts-cardtheme">
                        <Card.Img variant="top" src={smartfield} className='homeproducts-cardimg' />
                        <Card.Body>
                            <Card.Title className='homeproducts-cardtitle'>Smartfield 360°</Card.Title>
                            <Card.Text className='homeproducts-cardtext'>
                            Plataforma de realidad virtual del campo Los Tilos, con demostración de tecnologías
                            </Card.Text>
                            <Button className='homeproducts-button' variant="success" href='https://www.smartfieldinia.cl/plataforma/' >Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3 homeproducts-cardtheme">
                        <Card.Img variant="top" src={biblioteca} className='homeproducts-cardimg' />
                        <Card.Body>
                            <Card.Title className='homeproducts-cardtitle'>Biblioteca digital</Card.Title>
                            <Card.Text className='homeproducts-cardtext'>
                            Recursos digitales sobre tecnología agrícola para educación e innovación.
                            </Card.Text>
                            <Button className='homeproducts-button' variant="success" href='https://www.smartfieldinia.cl/biblioteca' >Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="mb-3 homeproducts-cardtheme">
                        <Card.Img variant="top" src={vigilancia} className='homeproducts-cardimg' />
                        <Card.Body>
                            <Card.Title className='homeproducts-cardtitle'>Vigilancia tecnológica</Card.Title>
                            <Card.Text className='homeproducts-cardtext'>
                            Monitoreo constante de avances en tecnología agrícola.
                            </Card.Text>
                            <Button className='homeproducts-button' variant="success" href='https://agriculturadigital.cepal.org/es/vigilancia-tecnologica' >Ver más</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Products;
