import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Mission.css';
import Img1 from './assets/mission1.jpg';
import Img2 from './assets/mission2.jpg';

const Mission = () => {
    return (
        <Container className='homemissioncomponent'>
            <Row>
                <Col md={6} className="homemission-leftcolumn">
                    <h2 className="homemission-title">Nuestro Objetivo</h2>
                    <p className="homemission-paragraph">
                        Crear ecosistemas colaborativos de empresas público-privadas que permitan la penetración y capacitación de las tecnologías emergentes dentro del sector 
                        productivo agrícola. Trabajamos con variedad de empresas para que el usuario pueda tener libre elección al momento de implementar tecnología en su campo, 
                        además te asesoramos para que tengas el mejor desempeño.
                        <br/>
                        El proyecto SmartField INIA comprende dos unidades estratégicamente ubicadas: SmartField INIA Los Tilos, abarcando las regiones de Valparaíso, Metropolitana y 
                        O'Higgins, y SmartField INIA Zona Centro Sur, que se extiende por las regiones de Maule, Ñuble y Biobío.
                    </p> 
                </Col>
                <Col md={6}>
                    <Carousel className='homemission-carousel' interval={10000}>
                        <Carousel.Item >
                            <img
                                className="d-block w-100 homemission-item"
                                src={Img2}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 homemission-item"
                                src={Img1}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default Mission;
