import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Capabilities.css';
import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.jpg'

const Capabilities = () => {
    return (
        <Container className='Capabilitiescomponent'>
            <h2 className="text-center Capabilities-title">Generar Capacidades</h2>
            <Row>
                <Col md={4}>
                    <Card className="mb-4 Capabilities-cardtheme">
                        <Card.Img variant="top" src={img1} className='Capabilities-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities-cardtitle'>Capacitación e integración agronómica</Card.Title>
                            <Card.Text className='Capabilities-cardtext'>
                            Realizar capacitaciones, visitas a terreno y días de campo con INDAP y sus usuarios de las regiones de Valparaíso, Metropolitana, OHiggins y Maule.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 Capabilities-cardtheme">
                        <Card.Img variant="top" src={img3} className='Capabilities-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities-cardtitle'>Colaboración</Card.Title>
                            <Card.Text className='Capabilities-cardtext'>
                            Este proyecto es un convenio entre INIA e INDAP que tiene como objetivo implementar un sistema piloto de seguimiento y control hídrico en usuarios INDAP.
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-4 Capabilities-cardtheme">
                        <Card.Img variant="top" src={img2} className='Capabilities-cardimg' />
                        <Card.Body>
                            <Card.Title className='Capabilities-cardtitle'>Tecnologías</Card.Title>
                            <Card.Text className='Capabilities-cardtext'>
                                         EI seguimiento y control hídrico se realiza a través de sensores de humedad e imágenes satelitales, instalado en usuarios INDAP monitoreados por INIA y SATs
                            </Card.Text>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Capabilities;
