import React from 'react';
import Explication from '../components/Campus/Explication';
import TrainingAvaible from '../components/Campus/TrainingAvaible';
import CourseIframe from '../components/Campus/CourseIframe';
import Banner from '../components/Campus/Banner';

const Campus = () => {
   return (
       <div>
        <Banner/>
        <Explication/>
        <TrainingAvaible/>
        <CourseIframe/>              
       </div>
   );
};

export default Campus;

//Pagina CAMPUS