import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Enterprise.css';

import image1 from '../Home/assets/Enterprise/image1.jpg';
import image2 from '../Home/assets/Enterprise/image2.jpg';
import image3 from '../Home/assets/Enterprise/image3.jpg';
import image4 from '../Home/assets/Enterprise/image4.jpg';
import image5 from '../Home/assets/Enterprise/image5.jpg';
import image6 from '../Home/assets/Enterprise/image6.jpg';
import image7 from '../Home/assets/Enterprise/image7.jpg';
import image8 from '../Home/assets/Enterprise/image8.jpg';
import image9 from '../Home/assets/Enterprise/image9.jpg';
import image10 from '../Home/assets/Enterprise/image10.jpg';
import image11 from '../Home/assets/Enterprise/image11.jpg';
import image12 from '../Home/assets/Enterprise/image12.jpg';
import image13 from '../Home/assets/Enterprise/image13.jpg';
import image14 from '../Home/assets/Enterprise/image14.jpg';
import image15 from '../Home/assets/Enterprise/image15.jpg';
import image16 from '../Home/assets/Enterprise/image16.jpg';
import image17 from '../Home/assets/Enterprise/image17.jpg';
import image18 from '../Home/assets/Enterprise/image18.jpg';
import image19 from '../Home/assets/Enterprise/image19.jpg';
import image20 from '../Home/assets/Enterprise/image20.jpg';
import image21 from '../Home/assets/Enterprise/image21.jpg';
import image22 from '../Home/assets/Enterprise/image22.jpg';
import image23 from '../Home/assets/Enterprise/image23.jpg';
import image24 from '../Home/assets/Enterprise/image24.jpg';

const images = [
    image1, image2, image3, image4, image5, image6,
    image7, image8, image9, image10, image11, image12,
    image13, image14, image15, image16, image17, image18,
    image19, image20, image21, image22, image23, image24
];

const Enterprise = () => {
    const isMobile = window.innerWidth <= 768;
    const chunkSize = isMobile ? 3 : 6; // 3 images per item on mobile, 6 on desktop
    const carouselItems = [];

    for (let i = 0; i < images.length; i += chunkSize) {
        const chunk = images.slice(i, i + chunkSize);
        carouselItems.push(
            <Carousel.Item key={i}>
                <div className="homeenterprisecarousel-row">
                    {chunk.map((image, index) => (
                        <img
                            className="d-block w-100 homeenterprisecarousel-image"
                            src={image}
                            alt={`Slide ${i + index}`}
                            key={index}
                        />
                    ))}
                </div>
            </Carousel.Item>
        );
    }

    return (
        <div className="homeenterpriseenterprise-container">
            <h3 className="homeenterpriseenterprise-title">Conoce más de empresas asociadas</h3>
            <Carousel>{carouselItems}</Carousel>
        </div>
    );
};

export default Enterprise;