import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MoreDocuments.css";

const MoreDocuments = () => {
  return (
    <Container className="more-documents-container">
      <Row>
        <Col className="text-center">
          <h2>¿Deseas conocer más recursos?</h2>
          <Button variant="success" href="https://biblioteca.inia.cl" target="_blank">
            Ir a la Biblioteca
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default MoreDocuments;
