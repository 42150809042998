import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Banner.css';
import clip1 from './clip2.mov';

const Banner = () => {
    return (
        <div className="BannerContainer">
            <video className="BannerClip" src={clip1} autoPlay loop muted />
            <div className="BannerOverlay">
                <div className="BannerCard">
                    <h3 className="BannerTitle">Capacitación online</h3>
                    <p className="BannerParagraph">
                    Las capacitaciones son de carácter práctico basadas en las tecnologías visualizadas en unidades demostrativas ofrecen a productores y técnicos formación interactiva y a distancia. Iniciativa financiado por la Corporación de Fomento de la Producción.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Banner;