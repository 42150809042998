import React from 'react';
import AllPosts from '../components/Events/Events';

const Events = () => {
   return (
       <div>
        <AllPosts/>   
       </div>
   );
};

export default Events;

//Pagina Events