import React from 'react';
import './Crisis.css';

const Crisis = () => {
    return (
        <div className="homecrisis-container">
            <h3 className="homecrisis-title">Crisis Climática</h3>
            <div className="homecrisis-video-wrapper">
                <iframe 
                    className="hoomecrisis-video"
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/5sQki1L2CK4?si=eVRzRKmxZCSEVeX-"
                    title="YouTube video player" 
                    frameBorder="" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    );
};

export default Crisis;
