import React from 'react';
import ProcessValidation from '../components/Smartfield/ProcessValidation';
import FlowChart from '../components/Smartfield/FlowChart'
import Training from '../components/Smartfield/Training'
import TrainingTemactics from '../components/Smartfield/TrainingTematics';
import IntroSmartfield from '../components/Smartfield/IntroSmartfield';

const Smartfield = () => {
   return (
    <div>
      <IntroSmartfield/>
     <ProcessValidation/>
     <FlowChart/>
     <Training/>
     <TrainingTemactics/>
    </div>
   );
};

export default Smartfield;