import React from 'react';
import Intro from '../components/Home/Intro';
import Mission from '../components/Home/Mission';
import Products from '../components/Home/Products';
import Crisis from '../components/Home/Crisis';
import Enterprise from '../components/Home/Enterprise';
import About from '../components/Home/About';

const Home = () => {
   return (
       <div>
           <Intro/>
           <Mission/>
           <About/>
           <Products/>
           <Crisis />
           <Enterprise/>
       </div>
   );
};

export default Home;

//Pagina de INICIO