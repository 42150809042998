import React from 'react';
import Indap from '../components/INDAP/Indap';
import SpecificConvent from '../components/INDAP/SpecificConvent';
import Capabilities from '../components/INDAP/Capabilities';
import FlowChart from '../components/Smartfield/FlowChart'
import Banner from '../components/INDAP/Banner';

const INDAP = () => {
   return (
       <div>
            <Banner/>     
           <Indap/>
           <SpecificConvent/>
           <Capabilities/>
           <FlowChart/>
       </div>
   );
};

export default INDAP;

//Pagina INDAP
