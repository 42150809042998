import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Training.css';
import { Carousel, Card, Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

import ico1 from './assets/icono1.png'
import ico2 from './assets/icono2.png'
import ico3 from './assets/icono3.png'
import ico4 from './assets/icono4.png'

//Importar empresas
import AgroPrecision from '../Home/assets/Enterprise/image3.jpg'
import DropControl from '../Home/assets/Enterprise/image7.jpg'
import Auravant from '../Home/assets/Enterprise/image5.jpg'
import GeoTrace from '../Home/assets/Enterprise/image10.jpg'
import Instacrops from '../Home/assets/Enterprise/image15.jpg'
import Mapsens from '../Home/assets/Enterprise/image16.jpg'
import AquaDetect from '../Home/assets/Enterprise/AQUADETECT.png'
import lemSystem from '../Home/assets/Enterprise/LemSystem.png'
import OneSoil from '../Home/assets/Enterprise/image21.jpg' 
import Olivos from '../Home/assets/Enterprise/image20.jpg'
import Impac from '../Home/assets/Enterprise/image12.jpg'
import Negume from '../Home/assets/Enterprise/image19.jpg'
import TC from '../Home/assets/Enterprise/image23.jpg'
import INIA from '../Home/assets/Enterprise/image14.jpg'
import Kimenda from '../Home/assets/Enterprise/kimenda.jpeg'
import Soval from '../Home/assets/Enterprise/image22.jpg'
import Agrometeorologia from '../Home/assets/Enterprise/image2.jpg'
import AgroSmart from '../Home/assets/Enterprise/image4.jpg'
import TheEarthSays from '../Home/assets/Enterprise/EarthSays.jpeg'
import TrackStack from '../Home/assets/Enterprise/TrackStack.png'
import Hispatec from '../Home/assets/Enterprise/image11.jpg'
import MindLabs from '../Home/assets/Enterprise/image17.jpg'
import AgroInventario from '../Home/assets/Enterprise/AgroInventario.png' 
import CampoConectado from '../Home/assets/Enterprise/Campoconectado.png'
import MorphoIA from '../Home/assets/Enterprise/MorphoIA.png'
import OlivosRiego from '../Home/assets/Enterprise/OlivosRiego.png'
import BigGrow from '../Home/assets/Enterprise/BigGrow.png'
import HoneyPro from '../Home/assets/Enterprise/honeypro.jpeg'
import SummitAgro from '../Home/assets/Enterprise/summitagro.png'
import Drops from '../Home/assets/Enterprise/Drops.png'
import TecnoMundo from '../Home/assets/Enterprise/TECNOMUNDO.png'
import Nutrient from '../Home/assets/Enterprise/Nutrient.png'
import Sofia from '../Home/assets/Enterprise/Sofia.png'
import Iwie from '../Home/assets/Enterprise/Iwie.png'
import AFIPA from '../Home/assets/Enterprise/AFIPA.jpeg'
import FIA from '../Home/assets/Enterprise/FIA.png'
import UTalca from '../Home/assets/Enterprise/Utalca.svg.png'
import UAB from '../Home/assets/Enterprise/UAB.png'
import AgroTech from '../Home/assets/Enterprise/AgroTech.png'

import ColegioAgro from '../Home/assets/Enterprise/ColegioAgro.jpeg'
import ICA from '../Home/assets/Enterprise/ICA.jpg'
import CEPAL from '../Home/assets/Enterprise/images.png'
import INDAP from '../Home/assets/Enterprise/INDAP.jpg'
import Procisur from '../Home/assets/Enterprise/Procisur.png'
import UDLA from '../Home/assets/Enterprise/UDLA.jpg'
import RIDAG from '../Home/assets/Enterprise/RIDAG.png'
import Inacap from '../Home/assets/Enterprise/Inacap.png'


const TrainingTemactics = () => {
    return (
        <Container className="Training-container">
            <h3 className="Training-title">Temáticas de Capacitación</h3>
            <Row>
                <p className='Training-paragraph'>
                Para que esto ocurra, será clave la generación de recursos humanos asociados a estas tecnologías. Es necesario un escalamiento desde las capacidades agronómicas tradicionales hacia la generación de capacidades agronómicas digitales, que permitan optimizar los tiempos, la eficiencia y efectividad de los manejos agronómicos frente a los cambios climáticos imperantes, para permitir una mejor competitividad en el sector productivo agrícola.
                </p>
            </Row>
            <Row>

                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico3} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Big Data</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                  
                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico2} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Ambientación</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico4} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Maquinaria Inteligente</Card.Title>
                        </Card.Body>
                    </Card>  
                </Col>
                  
                <Col md={3}>
                    <Card className='TematicsTraining-card'>
                        <Card.Img src={ico1} className='TematicsTraining-icoImage' />
                        <Card.Body>
                            <Card.Title className='TematicsTraining-icoTitle'>Sensoramiento</Card.Title> 
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            
            <Row>
                <p className='Training-paragraph'>
                Dentro del Centro Experimental INIA Los Tilos tenemos un ecosistema de diferentes tecnologías que comprenden empresas que están desarrollando soluciones integradas al Smartfield INIA. Dentro de las capacitaciones se muestra de forma práctica la integración de ellas.
                </p>
                <br></br>
                <p className='Training-paragraph'>
                Pincha para ver las empresas involucradas.
                </p>
            </Row>
            
            <Row>
                <Tabs defaultActiveKey="tab1" id="tabEnterprise" fill success className='tab-custom'>
                    <Tab eventKey="tab1" title="RIEGO"> 
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={GeoTrace} alt="GeoTrace" /></Col>
                                    <Col><img className="d-block w-100" src={AquaDetect} alt="AquaDetect" /></Col>
                                    <Col><img className="d-block w-100" src={CampoConectado} alt="CampoConectado" /></Col>
                                    <Col><img className="d-block w-100" src={lemSystem} alt="LemSystem" /></Col>
                                    <Col><img className="d-block w-100" src={Instacrops} alt="Instacrops" /></Col>
                                  
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={MorphoIA} alt="MorphoIA" /></Col>
                                    <Col><img className="d-block w-100" src={DropControl} alt="DropControl" /></Col>
                                    <Col><img className="d-block w-100" src={OlivosRiego} alt="OlivosRiego" /></Col>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoils" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab2" title="FITOSANIDAD" >
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={Impac} alt="Impac" /></Col>
                                    <Col><img className="d-block w-100" src={BigGrow} alt="BigGrow" /></Col>
                                    <Col><img className="d-block w-100" src={Negume} alt="Negume" /></Col>
                                    <Col><img className="d-block w-100" src={Iwie} alt="Iwie" /></Col>
                                    <Col><img className="d-block w-100" src={Drops} alt="Drops" /></Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={AgroInventario} alt="AgroInventario" /></Col>
                                    <Col><img className="d-block w-100" src={Kimenda} alt="Kimenda" /></Col>
                                    <Col><img className="d-block w-100" src={HoneyPro} alt="HoneyPro" /></Col>
                                    <Col><img className="d-block w-100" src={TecnoMundo} alt="Tecnomundo" /></Col>
                                    <Col><img className="d-block w-100" src={TheEarthSays} alt="TheEarthSays" /></Col>
                                    <Col><img className="d-block w-100" src={SummitAgro} alt="SummitAgro" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab3" title="FERTILIDAD" >
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={Soval} alt="Soval" /></Col>
                                    <Col><img className="d-block w-100" src={BigGrow} alt="BigGrow" /></Col>
                                    <Col><img className="d-block w-100" src={Nutrient} alt="Nutrient" /></Col>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoil" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab4" title="BIG DATA" > 
                        <Carousel>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={Auravant} alt="Auravant" /></Col>
                                    <Col><img className="d-block w-100" src={Agrometeorologia} alt="AgroMeteorologia" /></Col>
                                    <Col><img className="d-block w-100" src={AgroPrecision} alt="AgroPrecision" /></Col>
                                    <Col><img className="d-block w-100" src={Hispatec} alt="Hispatec" /></Col>
                                    <Col><img className="d-block w-100" src={MindLabs} alt="MindLabs" /></Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col><img className="d-block w-100" src={TrackStack} alt="TrackStack" /></Col>
                                    <Col><img className="d-block w-100" src={OneSoil} alt="OneSoil" /></Col>
                                    <Col><img className="d-block w-100" src={AgroSmart} alt="AgroSmart" /></Col>
                                    <Col><img className="d-block w-100" src={Sofia} alt="Sofia" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>

                    <Tab eventKey="tab5" title="INSTITUCIONES TRANSVERSALES EN LAS VERTICALES" > 
                        <Carousel>
                            <Carousel.Item>
                                <Row>

                                    <Col><img className="d-block w-100" src={AFIPA} alt="AFIPA" /></Col>
                                    <Col><img className="d-block w-100" src={FIA} alt="FIA" /></Col>
                                    <Col><img className="d-block w-100" src={UTalca} alt="Universidad de Talca" /></Col>
                                    <Col><img className="d-block w-100" src={UAB} alt="Universidad Andres Bello" /></Col>
                                    <Col><img className="d-block w-100" src={AgroTech} alt="AgroTech CHILE" /></Col>
                                    <Col><img className="d-block w-100" src={ICA} alt="ICA" /></Col>
                                    

                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                <Col><img className="d-block w-100" src={CEPAL} alt="CEPAL" /></Col>
                                    <Col><img className="d-block w-100" src={INDAP} alt="INDAP" /></Col>
                                    <Col><img className="d-block w-100" src={Procisur} alt="Procisur" /></Col>
                                    <Col><img className="d-block w-100" src={UDLA} alt="UDLA" /></Col>
                                    <Col><img className="d-block w-100" src={Inacap} alt="Inacap" /></Col>
                                    <Col><img className="d-block w-100" src={RIDAG} alt="Ridag" /></Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                    </Tab>
                </Tabs>
            </Row>
        </Container>
    );
};

export default TrainingTemactics;
