import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const CourseIframe = () => {
    return (
        <Container className='my-4'>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <iframe
                                src="https://smartfieldinia.cl/cursos/riego/Sala/"
                                width="100%"
                                height="800px"
                                style={{ border: 0 }}
                                title="Curso de Riego"
                            ></iframe>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CourseIframe;
