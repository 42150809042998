import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Training.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Training = () => {
    return (
        <Container className="Training-container">
            <h3 className="Training-title">Capacitaciones</h3>
            <Row>
                <p className='Training-paragraph'>
                Para que esto ocurra, será clave la generación de recursos humanos asociados a estas tecnologías. Es necesario un escalamiento desde las capacidades agronómicas tradicionales hacia la generación de capacidades agronómicas digitales, que permitan optimizar los tiempos, la eficiencia y efectividad de los manejos agronómicos frente a los cambios climáticos imperantes, para permitir una mejor competitividad en el sector productivo agrícola.
                </p>
            </Row>
        </Container>
    );
};

export default Training;