import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProcessValidation.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from './assets/processValidation1.jpg'
import check from './assets/check.svg'

const ProcessValidation = () => {
    return (
        <Container className="ProcessValidation-container" id='Process'>
            <h3 className="ProcessValidation-title">Proceso de validación de las tecnologías</h3>
            <Row>
                <Col md={5}>
                  
                <img
                                className="d-block w-100 SmartfieldProcessValidation-img"
                                src={img1}
                                alt="Second slide"
                />
                </Col>
                
                <Col xs={1}>
                    <Row className='SmartfieldProcessValidation-Row'>
                        <img
                                        className="d-block w-100 SmartfieldProcessValidation-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldProcessValidation-Row'> 
                        <img
                                        className="d-block w-100 SmartfieldProcessValidation-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldProcessValidation-Row'>
                        <img
                                        className="d-block w-100 SmartfieldProcessValidation-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldProcessValidation-Row'>
                        <img
                                        className="d-block w-100 SmartfieldProcessValidation-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                </Col>
                
                <Col xs={6}>

                    <Row className='SmartfieldProcessValidation-Row'>
                        <Card className="ProcessValidation-card">
                            <Card.Body>
                                <Card.Text className="ProcessValidation-card-text">
                                Crear un acuerdo entre los actores del proceso: La empresa que esté validando su tecnología deberá entregar toda la información para luego proyectar las expectativas.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='SmartfieldProcessValidation-Row'>
                        <Card className="ProcessValidation-card">
                            <Card.Body>
                                <Card.Text className="ProcessValidation-card-text">
                                Se realizará un protocolo de validación donde se creará un modelo de ensayo que requiera la validación de la tecnología.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    
                    <Row className='SmartfieldProcessValidation-Row'>
                        <Card className="ProcessValidation-card">
                            <Card.Body>
                                <Card.Text className="ProcessValidation-card-text">
                                Al momento de ejecutar el ensayo, se deben tener los materiales que este requería, un lugar realista y se dejará registro de la ejecución de este. (que la empresa de verdad hace lo que hace) 
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='SmartfieldProcessValidation-Row'>
                        <Card className="ProcessValidation-card">
                            <Card.Body>
                                <Card.Text className="ProcessValidation-card-text">
                                Y se finaliza con una evaluación final de los datos obtenidos y se procede a la integración dentro del ecosistema Smartfield INIA.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default ProcessValidation;
