import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './IntroSmartfield.css';
import clip1 from './clip2.mov';
import Button from 'react-bootstrap/Button';

const IntroSmartfield = () => {
    return (
        <div className="smartfieldintro-container">
            <video className="smartfieldintro-video" src={clip1} autoPlay loop muted />
            <div className="smartfieldintro-overlay">
                <div className="smartfieldintro-card">
                    <h3 className="smartfieldintro-title">Smartfield INIA</h3>
                    <div className="introhome-buttons">
                        <Button variant="success buttonSmartfieldintro" href='#Process'>Proceso de validación</Button>
                        <Button variant="success buttonSmartfieldintro" href='#Flowchart'>Flujograma</Button>
                   </div>
                </div>
            </div>
        </div>
    );
};

export default IntroSmartfield;