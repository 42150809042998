import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Events.css'

const AllPosts = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 10;

    useEffect(() => {
        fetch('http://70.35.199.79:4580/api/posts')
            .then(response => response.json())
            .then(data => setPosts(data));
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const totalPages = Math.ceil(posts.length / postsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Container className='containerPosts'>
            <Row>
                {currentPosts.map(post => (
                    <Col key={post.id} md={12} className="mb-4">
                        <Card className='cardPosts'>
                            <Row noGutters>
                                <Col md={4}>
                                    <Carousel>
                                        {post.images.map((image, index) => (
                                            <Carousel.Item key={index} className='imgPost'>
                                                <img
                                                    className="imgPost"
                                                    src={`http://70.35.199.79:4580${image}`}
                                                    alt={`Slide ${index}`}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Col>
                                <Col md={8}>
                                    <Card.Body>
                                        <Card.Title className='titlepost'>{post.title}</Card.Title>
                                        <Card.Text className='parrafopost'>{post.content}</Card.Text>
                                        <div>
                                            <Button variant="success" href={post.links.linkedin} className="mr-2 postButton">LinkedIn</Button>
                                            <Button variant="success" href={post.links.facebook} className="mr-2 postButton">Facebook</Button>
                                            <Button variant="success" href={post.links.instagram} className='postButton'>Instagram</Button>
                                        </div>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Pagination>
                {[...Array(totalPages).keys()].map(number => (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handleClick(number + 1)}>
                        {number + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </Container>
    );
};

export default AllPosts;
