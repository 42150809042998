import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Explication.css';

const Explication = () => {
    return (
        <Container>
            <Row>
                 <p className="Explication-paragraph">
                 Las capacitaciones virtuales prácticas son sesiones interactivas y gratuitas, ofrecidas a agricultores, estudiantes, profesionales y técnicos del área agrícola interesados en adoptar tecnologías agrícolas avanzadas. A través de una plataforma e-learning, estas capacitaciones presentan de manera simulada y en tiempo real el uso y los beneficios de las innovaciones mostradas en unidades demostrativas. Con cupos limitados para garantizar una experiencia de aprendizaje óptima, estas sesiones proporcionan ejemplos concretos y facilitan la comprensión técnica necesaria para implementar soluciones modernas en operaciones agrícolas. Este formato promueve una rápida y efectiva adaptación a nuevas tecnologías, incrementando la productividad y sustentabilidad de las prácticas agrícolas.
                </p>
            </Row>
        </Container>
    );
};

export default Explication;
