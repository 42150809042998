import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Corfo.css";
import img1 from "./assets/Corfo1.png";

const Corfo = () => {
  return (
    <Container>
      <Row>
        <h2 className="Corfo-title">Corporación de Fomento de la Producción</h2>
      </Row>
      <Row>
        <Col md={6} className="Corfo-left-column">
          <img className="Corfo-img" src={img1}></img>
        </Col>

        <Col md={6}>
          <p className="Corfo-paragraph">
          Fomentar la inversión, la innovación y el emprendimiento, fortaleciendo, además, el capital humano y el desarrollo tecnológico para mejorar la productividad del país y alcanzar posiciones de liderazgo mundial en materia de competitividad.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Corfo;
