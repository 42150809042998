import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Indap.css';
import img1 from './assets/Indap1.jpeg'

const Indap = () => {
    return (
        <Container>
            <Row className='indapgroup'> 

                <Col md={6} className="Indap-left-column" >
                <h2 className="Indap-title">INDAP</h2>
                    <p className="Indap-paragraph">
                    INDAP promueve el desarrollo de productores agrícolas y campesinos, generando alimentos saludables en entornos sustentables
                    </p>
                </Col>
                <Col md={6}>
                    <img className='Indap-img' src={img1}></img>
                </Col>
            </Row>
        </Container>
    );
};

export default Indap;
