import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TrainingAvaible.css";
import img1 from "./assets/mecanizacion-inteligente.jpg";
import img2 from "./assets/fertilidad.jpg";
import img3 from "./assets/riego.jpg";


const TrainingAvaible = () => {
  return (
    <Container className="TrainingAvaiblecomponent">
      <h2 className="text-center TrainingAvaible-title">Cursos</h2>
      <Row>
        <Col md={4}>
          <Card className="mb-4 TrainingAvaible-cardtheme">
            <Card.Img
              variant="top"
              src={img3}
              className="TrainingAvaible-cardimg"
            />
            <Card.Body>
              <Card.Title className="TrainingAvaible-cardtitle">
                Curso de Riego
              </Card.Title>
              <Card.Text className="TrainingAvaible-cardtext">
                El curso abarca desde sistemas de riego hasta la gestión del cambio climático, bajo una perspectiva de uso de herramientas digitales. Este curso no solo enseña técnicas de riego eficiente sino que también prepara a los agricultores para adoptar prácticas digitales que mejoren la sostenibilidad de sus operaciones. Al finalizar, los participantes reciben certificación que respalda su habilidad para integrar estas tecnologías en sus prácticas diarias, fomentando un uso más racional y eficiente del agua.
              </Card.Text>
              <Button className="TrainingAvaible-button" variant="success" href="https://smartfieldinia.cl/campus/">
                Ver más
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 TrainingAvaible-cardtheme">
            <Card.Img
              variant="top"
              src={img2}
              className="TrainingAvaible-cardimg"
            />
            <Card.Body>
              <Card.Title className="TrainingAvaible-cardtitle">
                Curso de Fertilidad
              </Card.Title>
              <Card.Text className="TrainingAvaible-cardtext">
              El curso abarca desde sistemas de riego hasta la gestión del cambio climático, bajo una perspectiva de uso de herramientas digitales. Este curso no solo enseña técnicas de riego eficiente sino que también prepara a los agricultores para adoptar prácticas digitales que mejoren la sostenibilidad de sus operaciones. Al finalizar, los participantes reciben certificación que respalda su habilidad para integrar estas tecnologías en sus prácticas diarias, fomentando un uso más racional y eficiente del agua.
              </Card.Text>
              <Button className="TrainingAvaible-button" variant="success" href="https://smartfieldinia.cl/campus/">
                Ver más
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 TrainingAvaible-cardtheme">
            <Card.Img
              variant="top"
              src={img1}
              className="TrainingAvaible-cardimg"
            />
            <Card.Body>
              <Card.Title className="TrainingAvaible-cardtitle">
              Curso de Mecanización Inteligente
              </Card.Title>
              <Card.Text className="TrainingAvaible-cardtext">
              Este curso ofrece una introducción integral a las últimas tecnologías en agricultura, desde principios físicos básicos, pasando la operación de maquinaria avanzada hasta el uso de drones y tecnologías de precisión. Los participantes explorarán cómo estas innovaciones pueden transformar las prácticas agrícolas, haciéndolas más eficientes y sostenibles. A través de ejemplos reales y análisis de casos, el curso destaca la importancia de la robotización y otras tecnologías emergentes en la mejora de la productividad y la gestión de recursos en la agricultura moderna.
              </Card.Text>
              <Button className="TrainingAvaible-button" variant="success" href="https://smartfieldinia.cl/campus/">
                Ver más
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TrainingAvaible;
