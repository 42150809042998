import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SpecificConvent2.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from './assets/SpecificConvent21.jpg'
import check from './assets/check.svg'

const SpecificConvent2 = () => {
    return (
        <Container className="SpecificConvent2-container">
            <h3 className="SpecificConvent2-title">Objetivos Específicos del Convenio</h3>
            <Row>
                <Col md={5}>
                  
                <img
                                className="d-block w-100 SmartfieldSpecificConvent2-img"
                                src={img1}
                                alt="Second slide"
                />
                </Col>
                
                <Col xs={1}>

                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent2-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldSpecificConvent2-Row'> 
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent2-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent2-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent2-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    
                </Col>
                
                <Col xs={6}>

                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <Card className="SpecificConvent2-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent2-card-text">
                                Coordinar la colaboración entre empresas AgTech en las áreas de riego, fertilidad y ﬁtosanidad, con el objetivo de estructurar un plan integral de difusión y transferencia tecnológica que promueva una gobernabilidad efectiva del ecosistema agrícola.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <Card className="SpecificConvent2-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent2-card-text">
                                Establecer una estructura demostrativa en el campo para orientar y demostrar la usabilidad práctica de las tecnologías AgTech en las áreas temáticas, con el potencial de generar impacto positivo en el sector de la región.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    
                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <Card className="SpecificConvent2-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent2-card-text">
                                Diseñar y desarrollar programas de capacitación efectivos y adaptados a las necesidades especíﬁcas de los profesionales y productores agrícolas del Biobío. Estas capacitaciones estarán vinculadas a la experiencia adquirida en las demostraciones de campo.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='SmartfieldSpecificConvent2-Row'>
                        <Card className="SpecificConvent2-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent2-card-text">
                                Elaborar un plan de sostenibilidad y escalabilidad para AgroConecta, enfocado en la continuidad y crecimiento del proyecto, el cual viabilice el impacto sostenible de las actividades y servicios proporcionados por AgroConecta de la comunidad agrícola del Biobío.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default SpecificConvent2;
