import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SpecificConvent.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from './assets/SpecificConvent1.jpg'
import check from './assets/check.svg'

const SpecificConvent = () => {
    return (
        <Container className="SpecificConvent-container">
            <h3 className="SpecificConvent-title">Objetivos Específicos del Convenio</h3>
            <Row>
                <Col md={5}>
                  
                <img
                                className="d-block w-100 SmartfieldSpecificConvent-img"
                                src={img1}
                                alt="Second slide"
                />
                </Col>
                
                <Col xs={1}>

                    <Row className='SmartfieldSpecificConvent-Row'>
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldSpecificConvent-Row'> 
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='SmartfieldSpecificConvent-Row'>
                        <img
                                        className="d-block w-100 SmartfieldSpecificConvent-arrow"
                                        src={check}
                                        alt="Second slide"
                        />
                    </Row>
                    
                </Col>
                
                <Col xs={6}>

                    <Row className='SmartfieldSpecificConvent-Row'>
                        <Card className="SpecificConvent-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent-card-text">
                                Incorporar Unidad Operativa SAT para cada una de las regiones seleccionadas, es decir, regiones de Valparaíso, Metropolitana, O' Higgins y Maule. Seleccionar predios pilotos y predios testigo a partir herramientas de segmentación multitemporales mediante uso de imágenes de satélite.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='SmartfieldSpecificConvent-Row'>
                        <Card className="SpecificConvent-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent-card-text">
                                Incorporar herramientas para monitoreo del desarrollo y estado de los cultivos, lo que incluye la instalación de sensores de humedad y el uso de plataformas satelitales. Implementar y capacitar a profesionales de INDAP y asesor por unidad operativa SAT.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    
                    <Row className='SmartfieldSpecificConvent-Row'>
                        <Card className="SpecificConvent-card">
                            <Card.Body>
                                <Card.Text className="SpecificConvent-card-text">
                                Realizar días de campo junto a usuarios pertenecientes a dichos grupos SAT donde se les asesore en el uso de estas nuevas tecnologías. Difundir y transferir los resultados del programa piloto, incorporando propuestas de adaptación de las tecnologías a la realidad de la agricultura familiar campesina.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default SpecificConvent;
