import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Ecosystem.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from './assets/ecosystem1.jpg'
import arrow from './assets/arrow.svg'

const Ecosystem = () => {
    return (
        <Container className="Ecosystem-container">
            <h3 className="Ecosystem-title">Importancia de generar un ecosistema
            </h3>
            <Row>
                <Col md={5}>
                  
                <img
                                className="d-block w-100 usEcosystem-img"
                                src={img1}
                                alt="Second slide"
                />
                </Col>
                
                <Col xs={1}>
                    <Row className='usEcosystem-Row'>
                        <img
                                        className="d-block w-100 usEcosystem-arrow"
                                        src={arrow}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='usEcosystem-Row'> 
                        <img
                                        className="d-block w-100 usEcosystem-arrow"
                                        src={arrow}
                                        alt="Second slide"
                        />
                    </Row>
                    <Row className='usEcosystem-Row'>
                        <img
                                        className="d-block w-100 usEcosystem-arrow"
                                        src={arrow}
                                        alt="Second slide"
                        />
                    </Row>
                </Col>
                
                <Col xs={6}>

                    <Row className='usEcosystem-Row'>
                        <Card className="Ecosystem-card">
                            <Card.Body className="Ecosystem-cardBody">
                                <Card.Text className="Ecosystem-card-text">
                                La solución que se visualiza a nivel global para enfrentar este problema es a través del uso eficiente de los recursos con un fuerte compromiso de sostenibilidad ambiental.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                    <Row className='usEcosystem-Row'>
                        <Card className="Ecosystem-card">
                            <Card.Body className="Ecosystem-cardBody">
                                <Card.Text className="Ecosystem-card-text">
                                Para que esto ocurra a nivel global, se están creando ecosistemas colaborativos de empresas público-privadas que permitan la penetración y capacitación de las tecnologías emergentes, dentro del sector productivo agrícola.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    
                    <Row className='usEcosystem-Row'>
                        <Card className="Ecosystem-card">
                            <Card.Body className="Ecosystem-cardBody">
                                <Card.Text className="Ecosystem-card-text">
                                En este contexto se crea SmartField INIA, como respuesta a la necesidad del ecosistema colaborativo público-privado antes expuesto.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
};

export default Ecosystem;
