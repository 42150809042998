import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Mission.css';
import img1 from './assets/mission1.png'

const Mission = () => {
    return (
        <Container>
            <Row>
                <Col md={9} className="usmission-left-column">
                    <h2 className="usmission-title">Misión y visión</h2>
                    <p className="usmission-paragraph">
                    Generar un ecosistema colaborativo económico en empresas de Agrotech,dirigido por el Programa de Agricultura Digital de INIA Chile, que habilite el escalamiento del uso de tecnologías digitales emergentes, a través de la generación de capacitaciones humanas y la habilitación de soluciones necesarias para mejorar la competitividad y sustentabilidad del sector productivo, buscando como objetivo producir más con menos.
                    El uso adecuado de tecnologías y digitalización agrícola permitirá abordar el problema de seguridad alimentaria, ambientalmente sostenible.
                    </p>
                </Col>
                <Col md={3}>
                    <img className='usmission-img' src={img1}></img>
                </Col>
            </Row>
        </Container>
    );
};

export default Mission;
