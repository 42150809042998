import React from 'react';
import Corfo from '../components/Agroconecta/Corfo'
import SpecificConvent2 from '../components/Agroconecta/SpecificConvent2';
import Capabilities2 from '../components/Agroconecta/Capabilities2';
import Banner from '../components/Agroconecta/Banner';

const Agroconecta = () => {
   return (
       <div>
            <Banner/>
           <Corfo/>
           <SpecificConvent2/>
           <Capabilities2/>
       </div>
   );
};

export default Agroconecta;

//Pagina AGROCONECTA