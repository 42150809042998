import React from 'react';
import BookShelf from '../components/Library/Bookshelf';
import MoreDocuments from '../components/Library/MoreDocuments';

const Library = () => {
   return (
       <div>   
        <BookShelf />
        <MoreDocuments/>
       </div>
   );
};

export default Library;

//Pagina Library