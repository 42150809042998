import React from 'react';
import Mission from '../components/Us/Mission';
import Ecosystem from '../components/Us/Ecosystem';
import Enterprise from '../components/Home/Enterprise'
import Testimony from '../components/Us/Testimony';
import Banner from '../components/Us/Banner';

const Us = () => {
   return (
       <div>
           <Banner />
            <Mission/>
            <Ecosystem/>
            <Testimony/>
            <Enterprise/>
       </div>
   );
};

export default Us;

//Pagina NOSOTROS