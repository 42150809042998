import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './Testimony.css'

const Testimony = () => {
    const videos = [
        'pkXqJ4A0knA',
    ];

    return (
        <Container className='carouselyoutube'>
        <Carousel>
            {videos.map((videoId, index) => (
                <Carousel.Item key={index}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            className="embed-responsive-item"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            allowFullScreen
                            title={`YouTube video ${index + 1}`}
                        ></iframe>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
        </Container>
    );
};

export default Testimony;
